import { T_NAMESPACES } from '@codeer/handypark-common';
import { phoneRegExp } from '@core';
import { t } from 'i18next';
import * as yup from 'yup';
import { PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS } from './';

const tKeyPrefix = 'parking-card-country-code-foreign.fill-phone-number.form';

export const parkingCardCountryCodeForeignFillPhoneNumberValidationSchema = () =>
  yup.object().shape({
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.PHONE_NUMBER]: yup
      .string()
      .matches(phoneRegExp, 'De ingevoerde gsm nummer is niet geldig. [TRANSLATE]')
      .required(
        t(`${tKeyPrefix}.phone-number.validation.invalid-format`, { ns: T_NAMESPACES.PAGES }),
      ),

    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PRIVACY_POLICY]: yup
      .bool()
      .test(
        'mustBeTrue',
        t(`${tKeyPrefix}.accept-privacy-policy.validation.required`, { ns: T_NAMESPACES.PAGES }),
        val => {
          return val;
        },
      )
      .required(
        t(`${tKeyPrefix}.accept-privacy-policy.validation.required`, { ns: T_NAMESPACES.PAGES }),
      ),

    [PARKING_CARD_COUNTRY_CODE_FOREIGN_FILL_PHONE_NUMBER_FORM_KEYS.ACCEPT_PROFESSIONAL_MESSAGES]:
      yup.bool(),
  });
