import { removeAccents } from '@codeer/handypark-common';
import { TCountryOptionWithAriaLabel } from '@features/parking-card-country-code';
import { TSortedCountries } from './';

export const divideCountriesByNeighbourhood = (
  countries: TCountryOptionWithAriaLabel[],
): TSortedCountries => {
  return countries.reduce(
    (acc: TSortedCountries, country) => {
      if (country.isNeighbouringCountry) {
        acc.neighbouring.push(country);
      } else {
        acc.other.push(country);
      }
      return acc;
    },
    { neighbouring: [], other: [] },
  );
};

export const filterCountriesByQuery = (
  countries: TCountryOptionWithAriaLabel[],
  query: string,
): TCountryOptionWithAriaLabel[] => {
  const q = removeAccents(query).toLowerCase();

  return countries.filter(c => {
    const iso2Code = c.iso2Code.toLowerCase();
    const label = c.label.toLowerCase();

    const containsIso2Code = iso2Code.includes(q);
    const containsLabel = label.includes(q) || removeAccents(label).includes(q);

    return containsIso2Code || containsLabel;
  });
};
