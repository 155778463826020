import { T_NAMESPACES } from '@codeer/handypark-common';
import { t } from 'i18next';
import * as yup from 'yup';
import { PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS } from './';

const tKeyPrefix = 'parking-card-country-code-foreign.confirm-phone-number.form';

export const parkingCardCountryCodeForeignConfirmPhoneNumberValidationSchema = () =>
  yup.object().shape({
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS.CODE]: yup
      .string()
      .test(
        'allFilled',
        t(`${tKeyPrefix}.code.validation.required`, {
          ns: T_NAMESPACES.PAGES,
          amountOfCharacters: 4,
        }),
        val => {
          if (!val) {
            return false;
          }
          return val.split('').filter(char => char === ' ').length === 0;
        },
      )
      .required(
        t(`${tKeyPrefix}.code.validation.required`, {
          ns: T_NAMESPACES.PAGES,
          amountOfCharacters: 4,
        }),
      ),
  });
