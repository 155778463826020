import {
  AppRoutes,
  AppSplashScreen,
  DATA_STATE,
  ErrorPage,
  useDocumentTitle,
} from '@codeer/handypark-web-common';
import { APP_ROUTES, PageLayout, useMain } from '@core';

import { FC } from 'react';

// TODO: Fetch user
export const Main: FC = () => {
  useDocumentTitle();

  const { dataState } = useMain();

  if (dataState === DATA_STATE.LOADING) {
    return <AppSplashScreen />;
  }

  if (dataState === DATA_STATE.ERROR) {
    return (
      <PageLayout>
        <ErrorPage />
      </PageLayout>
    );
  }

  const routes = APP_ROUTES();

  return (
    <PageLayout>
      <AppRoutes appRoutes={routes} />
    </PageLayout>
  );
};
