import { T_NAMESPACES } from '@codeer/handypark-common';
import { isInfoModalOpenAtom, PageHeaderActions } from '@codeer/handypark-web-common';
import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS,
  ParkingCardCountryCodeForeignConfirmPhoneNumberForm,
  ParkingCardCountryCodeForeignConfirmPhoneNumberInfoModal,
  parkingCardCountryCodeForeignStepAtom,
} from '@features/parking-card-country-code';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const ParkingCardCountryCodeForeignConfirmPhoneNumber = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code-foreign.confirm-phone-number',
  });

  const setParkingCardCountryCodeForeignStep = useSetAtom(parkingCardCountryCodeForeignStepAtom);
  const setInfoModalAtom = useSetAtom(isInfoModalOpenAtom);

  return (
    <>
      <PageHeaderActions
        title={t('title')}
        onPreviousHandler={() => {
          // Current step: PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.CONFIRM_PHONE_NUMBER
          setParkingCardCountryCodeForeignStep(
            PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.FILL_PHONE_NUMBER,
          );
        }}
        onInfoHandler={() => setInfoModalAtom(true)}
      />

      <ParkingCardCountryCodeForeignConfirmPhoneNumberInfoModal />

      <ParkingCardCountryCodeForeignConfirmPhoneNumberForm />
    </>
  );
};
