import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { AppModalInfo, Paragraph } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

type TParkingCardCountryCodeForeignConfirmPhoneNumberInfoModalProps = Qa;

export const ParkingCardCountryCodeForeignConfirmPhoneNumberInfoModal = ({
  qa = 'parking-card-country-code-foreign-info-modal',
}: TParkingCardCountryCodeForeignConfirmPhoneNumberInfoModalProps) => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code-foreign.confirm-phone-number.info',
  });

  return (
    <AppModalInfo qa={qa} title={t('title')}>
      <Paragraph>{t('description')}</Paragraph>
    </AppModalInfo>
  );
};
