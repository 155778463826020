import { T_NAMESPACES } from '@codeer/handypark-common';
import { AppButton, TAppButtonProps } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

type TAppButtonEIdasComponentProps = TAppButtonProps;

export const AppButtonEIdasComponent = ({
  qa = 'app-button-eidas',
  ...rest
}: TAppButtonEIdasComponentProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'button.sign-in' });

  return <AppButton {...rest}>{t('e-idas')}</AppButton>;
};
