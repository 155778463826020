import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  AppContainer,
  AppFormErrors,
  isInfoModalOpenAtom,
  PageHeaderActions,
} from '@codeer/handypark-web-common';
import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS,
  PARKING_CARD_COUNTRY_CODE_ROUTES,
  ParkingCardCountryCodeForeignSelectCountryForm,
  ParkingCardCountryCodeForeignSelectCountryInfoModal,
  parkingCardCountryCodeForeignStepAtom,
} from '@features/parking-card-country-code';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ParkingCardCountryCodeForeignSelectCountry = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code-foreign.select-country',
  });

  const setParkingCardCountryCodeForeignStep = useSetAtom(parkingCardCountryCodeForeignStepAtom);
  const setInfoModalAtom = useSetAtom(isInfoModalOpenAtom);

  const [formErrors, setFormErrors] = useState<string[]>([]);

  return (
    <>
      <PageHeaderActions
        title={t('title')}
        onPreviousHandler={() => {
          // Current step: PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.SELECT_COUNTRY
          setParkingCardCountryCodeForeignStep(
            PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.SELECT_COUNTRY,
          );

          // Go back to the selection of belgian/foreign parking card
          navigate(PARKING_CARD_COUNTRY_CODE_ROUTES().BASE);
        }}
        onInfoHandler={() => setInfoModalAtom(true)}
      />

      <ParkingCardCountryCodeForeignSelectCountryInfoModal />

      <AppContainer>
        <AppFormErrors formErrors={formErrors} mb={8} />
      </AppContainer>

      <ParkingCardCountryCodeForeignSelectCountryForm setFormErrors={setFormErrors} />
    </>
  );
};
