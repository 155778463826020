import { PARKING_CARD_ORIGIN_OPTIONS } from '@core';
import { t } from 'i18next';
import * as yup from 'yup';
import { PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS } from './';

export const parkingCardCountryCodeForeignSelectCountryValidationSchema = () =>
  yup.object().shape({
    [PARKING_CARD_COUNTRY_CODE_FOREIGN_SELECT_COUNTRY_FORM_KEYS.COUNTRY_CODE]: yup
      .string()
      .notOneOf([PARKING_CARD_ORIGIN_OPTIONS.OTHER], t('validation.required'))
      .required(t('validation.required')),
  });
