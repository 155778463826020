import { cleanPhoneNumber } from '@/core';
import { AppControlledInput, TAppControlledInputProps } from '@codeer/handypark-web-common';

import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';

type TAppControlledLicensePlateProps<T extends FieldValues> = TAppControlledInputProps<T> & {
  form: UseFormReturn<T>;
};

export const AppControlledPhoneNumber = <T extends FieldValues>({
  form,
  control,
  name,
  ...rest
}: TAppControlledLicensePlateProps<T>) => {
  const { setValue, getValues } = form;

  const setCleanedPhoneNumber = () => {
    const value = getValues(name);
    setValue(name, cleanPhoneNumber(value) as PathValue<T, Path<T>>);
  };

  return (
    <AppControlledInput name={name} control={control} onBlur={setCleanedPhoneNumber} {...rest} />
  );
};
