import { IRouteConfig } from '@codeer/handypark-web-common';
import { DEFAULT_ROUTES } from '@core/enums';
import { parkingCardCountryCodeRoutes } from '@features/parking-card-country-code';
import { lazy } from 'react';

const PageNotFound = lazy(() =>
  import('@codeer/handypark-web-common').then(module => ({
    default: module.PageNotFound,
  })),
);

const defaultRoutes = (): IRouteConfig[] => {
  return [
    {
      path: DEFAULT_ROUTES.NOT_FOUND,
      component: PageNotFound,
    },
    {
      path: '*',
      component: PageNotFound,
    },
  ];
};

const devRoutes: IRouteConfig[] = [];

export const APP_ROUTES: () => IRouteConfig[] = () => [
  ...(import.meta.env.DEV ? devRoutes : []),
  ...parkingCardCountryCodeRoutes(),
  ...defaultRoutes(),
];
