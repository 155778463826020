import { Image, ImageProps } from '@chakra-ui/react';
import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { useTranslation } from 'react-i18next';
import emojiEyesImage from './emoji-eyes.png';

type Props = Qa & ImageProps;

export const EmojiEyesImage = ({ qa = 'emoji-eyes-image', ...rest }: Props) => {
  const { t } = useTranslation(T_NAMESPACES.IMAGES);

  return <Image data-qa={qa} src={emojiEyesImage} alt={t('emoji-eyes')} {...rest} />;
};
