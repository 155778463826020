import { ISO_LANGUAGE_CODES } from '@codeer/handypark-common';
import { mergeDeep, TTranslation } from '@codeer/handypark-web-common';
import { Resource } from 'i18next';
import { enGB } from './en-GB';
import { nlBe } from './nl-BE';

export const translations: TTranslation[] = [
  {
    key: ISO_LANGUAGE_CODES.EN_GB,
    values: mergeDeep([enGB()]) as Resource,
  },
  {
    key: ISO_LANGUAGE_CODES.NL_BE,
    values: mergeDeep([nlBe()]) as Resource,
  },
];
