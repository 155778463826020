import { Icon } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import { TAppIconProps } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

export const EId = ({ qa = 'e-id-icon', ...rest }: TAppIconProps) => {
  const { t } = useTranslation(T_NAMESPACES.COMPONENTS, { keyPrefix: 'icons' });
  const title = t('e-id');
  return (
    <Icon
      data-qa={qa}
      aria-label={title}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 154.1 110.94'
      {...rest}
      fill='none'
      sx={{
        w: 'auto',
      }}
    >
      <rect y='15.33' width='138.05' height='79.93' rx='23.75' fill='#87cea3' />
      <circle cx='21.16' cy='72.21' r='3.6' fill='#000' />
      <path
        id='path4144'
        d='M26,74.52V33.79h6.88V48.17c10.29-2.89,14.85,1.15,17.87,7,4.94-12.13,24.68-11.8,23.67,8.48H56.69c1.23,9.49,11.51,7.62,14.83,5l2,4.58c-12.12,7.94-20.93.61-23.14-5.21C47.54,71.77,41.85,80.19,26,74.52Z'
        fill='#000'
      />
      <path id='path4146' d='M32.92,54.07c16.43-6.41,15.84,21.12,0,16.26Z' fill='#f9e819' />
      <path id='path4150' d='M56.28,58.94c0-9.06,11.24-8.69,11.24,0Z' fill='#e2141a' />
      <rect x='81.83' y='33.79' width='6.8' height={43} fill='#fff' />
      <path
        d='M91.31,33.79h12.1c12.14,0,17.09,7,17.09,20.53,0,14.55-4.65,22.47-17.8,22.47H91.31ZM98,71.27h4.59c7.56,0,11.09-5.11,11.09-16.87,0-10.41-3.52-15-11-15H98Z'
        fill='#fff'
      />
      <path
        d='M115.39,0H99.28V12h16.11A26.75,26.75,0,0,1,142.1,38.72v33.5a26.75,26.75,0,0,1-26.71,26.72H99.28v12h16.11A38.76,38.76,0,0,0,154.1,72.22V38.72A38.76,38.76,0,0,0,115.39,0Z'
        fill='#000'
      />
    </Icon>
  );
};
