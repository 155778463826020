import { Box } from '@chakra-ui/react';
import {
  InputError,
  RadioButtons,
  TControlledRadioButtonsProps,
} from '@codeer/handypark-web-common';
import { CountryRadioButtonTemplate } from '@core';
import { TCountryOptionWithAriaLabel } from '@features/parking-card-country-code';
import { Controller, FieldValues } from 'react-hook-form';

export const CountryRadioButtons = <T extends FieldValues>({
  qa = 'country-radio-buttons',
  name,
  control,
  isRequired,
  options,
  showErrorMessage = true,
  ...rest
}: TControlledRadioButtonsProps<T> & { options: TCountryOptionWithAriaLabel[] }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Box data-qa={qa}>
            <RadioButtons
              required={isRequired}
              name={name}
              options={options}
              value={field.value}
              onChange={v => field.onChange(v)}
              renderItem={(option: TCountryOptionWithAriaLabel, radio) => {
                return <CountryRadioButtonTemplate {...option} {...radio} />;
              }}
              {...rest}
            />
            <InputError show={showErrorMessage} fieldState={fieldState} />
          </Box>
        );
      }}
    />
  );
};
