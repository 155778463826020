import { AppHead } from '@codeer/handypark-web-common';

// TODO: Set different favicon based on color mode
export const HeadTags = () => {
  return (
    <AppHead>
      <meta name='description' content='Description' />
      <link rel='apple-touch-icon' sizes='180x180' href='/assets/icons/180x180.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/assets/icons/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/assets/icons/favicon-16x16.png' />
      <link rel='mask-icon' href='/assets/icons/icon.svg' color='#0008C4' />
      <meta name='msapplication-TileColor' content='#0008C4' />
      <meta name='theme-color' content='#0008C4' />
    </AppHead>
  );
};
