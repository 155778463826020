import { AppComponentSwitch } from '@codeer/handypark-web-common';
import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS,
  parkingCardCountryCodeForeignStepAtom,
} from '@features/parking-card-country-code';
import { useAtomValue } from 'jotai';
import { lazy } from 'react';

const SelectCountry = lazy(() =>
  import('../select-country').then(module => ({
    default: module.ParkingCardCountryCodeForeignSelectCountry,
  })),
);
const FillPhoneNumber = lazy(() =>
  import('../fill-phone-number').then(module => ({
    default: module.ParkingCardCountryCodeForeignFillPhoneNumber,
  })),
);
const ConfirmPhoneNumber = lazy(() =>
  import('../confirm-phone-number').then(module => ({
    default: module.ParkingCardCountryCodeForeignConfirmPhoneNumber,
  })),
);

export const ParkingCardCountryCodeForeignStepSwitchComponent = () => {
  const parkingCardCountryCodeForeignStep = useAtomValue(parkingCardCountryCodeForeignStepAtom);

  return (
    <AppComponentSwitch activeState={parkingCardCountryCodeForeignStep}>
      <SelectCountry data-index-name={PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.SELECT_COUNTRY} />
      <FillPhoneNumber
        data-index-name={PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.FILL_PHONE_NUMBER}
      />
      <ConfirmPhoneNumber
        data-index-name={PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.CONFIRM_PHONE_NUMBER}
      />
    </AppComponentSwitch>
  );
};
