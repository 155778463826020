import { Box } from '@chakra-ui/react';
import { T_NAMESPACES } from '@codeer/handypark-common';
import {
  ALERT_STATUS,
  APP_BUTTON_VARIANTS,
  AppAlert,
  AppButton,
  AppContainer,
  AppFormErrors,
  AppTrans,
  ControlledCodeInput,
  getErrorMessages,
  Paragraph,
  windowScrollTop,
} from '@codeer/handypark-web-common';
import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS,
  parkingCardCountryCodeForeignStepAtom,
  phoneNumberAtom,
} from '@features/parking-card-country-code';

import { yupResolver } from '@hookform/resolvers/yup';
import { useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { ReactNode, useState } from 'react';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS,
  parkingCardCountryCodeForeignConfirmPhoneNumberValidationSchema,
  TParkingCardCountryCodeForeignConfirmPhoneNumberForm,
} from './';

const defaultValues = {
  [PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS.CODE]: '',
};

const keyPrefix = 'parking-card-country-code-foreign.confirm-phone-number';
export const ParkingCardCountryCodeForeignConfirmPhoneNumberForm = () => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: `${keyPrefix}.form`,
  });

  const [formErrors, setFormErrors] = useState<ReactNode[]>([]);
  const resetFormErrors = () => {
    setFormErrors([]);
  };

  const [hasRequestedNewCode, setHasRequestedNewCode] = useState<boolean>(false);

  const phoneNumber = useAtomValue(phoneNumberAtom);
  const setParkingCardCountryCodeForeignStep = useSetAtom(parkingCardCountryCodeForeignStepAtom);

  const { handleSubmit, control, clearErrors, reset } =
    useForm<TParkingCardCountryCodeForeignConfirmPhoneNumberForm>({
      resolver: yupResolver(parkingCardCountryCodeForeignConfirmPhoneNumberValidationSchema()),
      mode: 'onSubmit',
      values: defaultValues,
      defaultValues,
    });

  const onRequestNewCodeHandler = () => {
    resetFormErrors();
    setHasRequestedNewCode(true);

    clearErrors(PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS.CODE);
    reset(defaultValues);
  };

  const onSubmit: SubmitHandler<TParkingCardCountryCodeForeignConfirmPhoneNumberForm> = data => {
    resetFormErrors();
    setHasRequestedNewCode(false);

    setParkingCardCountryCodeForeignStep(
      PARKING_CARD_COUNTRY_CODE_FOREIGN_STEPS.CONFIRM_PHONE_NUMBER,
    );

    // Do something with this code
    console.log({ data });
  };

  const onError: SubmitErrorHandler<
    TParkingCardCountryCodeForeignConfirmPhoneNumberForm
  > = data => {
    const newFormErrors = getErrorMessages(data);
    setFormErrors(newFormErrors);
    setHasRequestedNewCode(false);
    windowScrollTop();
  };

  // Wrap all functions that need to be done when submitting the form
  const onHandleSubmitHandler = () => {
    setHasRequestedNewCode(false);
    handleSubmit(onSubmit, onError)();
  };

  return (
    <form onSubmit={onHandleSubmitHandler}>
      <AppContainer mb={8}>
        <Paragraph mb={4}>
          <AppTrans
            i18nKey={`${keyPrefix}.description.code-received`}
            ns={T_NAMESPACES.PAGES}
            values={{
              phoneNumber,
            }}
          />
        </Paragraph>
        <Paragraph mb={8}>
          <AppTrans i18nKey={`${keyPrefix}.description.fill-code`} ns={T_NAMESPACES.PAGES} />
        </Paragraph>

        <AppFormErrors formErrors={formErrors} mb={8} />
        {hasRequestedNewCode && (
          <Box mb={8}>
            <AppAlert
              alert={{
                id: 'has-requested-new-code',
                status: ALERT_STATUS.POSITIVE,
                // TODO: Use AppTrans + get from translations
                content: (
                  <AppTrans
                    i18nKey={`${keyPrefix}.alert.requested-new-code`}
                    ns={T_NAMESPACES.PAGES}
                    values={{
                      phoneNumber,
                    }}
                  />
                ),
              }}
              canClose={false}
            />
          </Box>
        )}

        <ControlledCodeInput
          name={PARKING_CARD_COUNTRY_CODE_FOREIGN_CONFIRM_PHONE_NUMBER_FORM_KEYS.CODE}
          control={control}
          onSubmit={onHandleSubmitHandler}
        />
      </AppContainer>
      <AppContainer
        mb={8}
        display={'flex'}
        flexDirection={{ base: 'column', 'sm-md': 'row' }}
        gap={4}
      >
        <AppButton onClick={onHandleSubmitHandler}>{t('submit')}</AppButton>
        <AppButton variant={APP_BUTTON_VARIANTS.OUTLINE} onClick={onRequestNewCodeHandler}>
          {t('request-new-code')}
        </AppButton>
      </AppContainer>
    </form>
  );
};
