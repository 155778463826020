import { Qa, T_NAMESPACES } from '@codeer/handypark-common';
import { AppModalInfo, Paragraph } from '@codeer/handypark-web-common';
import { useTranslation } from 'react-i18next';

type TParkingCardCountryCodeForeignSelectCountryInfoModalProps = Qa;

export const ParkingCardCountryCodeForeignSelectCountryInfoModal = ({
  qa = 'parking-card-country-code-foreign-info-modal',
}: TParkingCardCountryCodeForeignSelectCountryInfoModalProps) => {
  const { t } = useTranslation(T_NAMESPACES.PAGES, {
    keyPrefix: 'parking-card-country-code-foreign.select-country.info',
  });

  return (
    <AppModalInfo qa={qa} title={t('title')}>
      <Paragraph>{t('description')}</Paragraph>
    </AppModalInfo>
  );
};
